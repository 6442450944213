import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, from } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { FlappingConfig } from "../../../../models/shared";

import { AuthService } from "src/app/services/auth.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class FlappingService {
    flappingConfig: Observable<FlappingConfig>;
    private flappingConfig$: ReplaySubject<FlappingConfig>;
    private dataStore: {
        flapping: FlappingConfig;
    };

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private zenApiService: ZenApiService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            flapping: undefined
        };

        this.flappingConfig$ = new ReplaySubject(1) as ReplaySubject<FlappingConfig>;
        this.flappingConfig = this.flappingConfig$.asObservable();
    }

    private prepFlappingConfig(item: FlappingConfig) {
        return item;
    }

    private updateStore(flappingConfig: FlappingConfig, merge: boolean): void {
        this.prepFlappingConfig(flappingConfig);

        if (merge) {
            const current = this.dataStore.flapping;

            this.dataStore.flapping = Object.assign(current || {}, flappingConfig);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.flapping = flappingConfig;
        }
    }

    refreshFlappingConfig(): Observable<FlappingConfig> {
        const flapping$ = from(this.zenApiService.client.configuration.getFlapping()).pipe(share());
        flapping$.subscribe(
            data => {
                if (data.body.success) {
                    const flapping = data.body.result;

                    this.updateStore(flapping, true);

                    this.flappingConfig$.next(Object.assign({}, this.dataStore).flapping);
                }
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return flapping$.pipe(
            map(r => {
                if (r.status === 200) return r.body.result;
                else throw new Error(r.body.error);
            })
        );
    }

    async upsertFlappingConfig(model: FlappingConfig): Promise<boolean> {
        try {
            const result = await this.zenApiService.client.configuration.upsertFlapping({ body: model });
            if (result.status !== 200) return false;

            this.updateStore(model, false);
            this.flappingConfig$.next(Object.assign({}, this.dataStore).flapping);

            return true;
        } catch (error) {
            return false;
        }
    }
}
